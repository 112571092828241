<template>
  <div class="upload-excel">
    <div class="btn-upload">
      <el-button :loading="loading" type="primary" @click="handleUpload">
        点击上传
      </el-button>
    </div>

    <input
      ref="excelUploadInput"
      class="excel-upload-input"
      type="file"
      accept=".xlsx, .xls"
      @change="handleChange"
    />
    <!-- https://developer.mozilla.org/zh-CN/docs/Web/API/HTML_Drag_and_Drop_API -->
    <!-- dorp： 当拖拽元素或选中的文本时触发-->
    <!-- dragover：当元素或选中的文本被拖到一个可释放目标上时触发（每 100 毫秒触发一次） -->
    <!-- dragenter：当拖拽元素或选中的文本到一个可释放目标时触发 -->
    <div
      class="drop"
      @drop.stop.prevent="handleDrop"
      @dragover.stop.prevent="handleDragover"
      @dragenter.stop.prevent="handleDragover"
    >
      <i class="el-icon-upload" />
      <span> 拖拽上传 </span>
    </div>
  </div>
</template>

<script setup>
import XLSX from 'xlsx'
import { ref, defineProps } from 'vue'
import { getHeaderRow, isExcel } from './utils'
import { ElMessage } from 'element-plus'
const props = defineProps({
  // 上传前回调
  beforeUpload: Function,
  // 成功回调
  onSuccess: Function
})

const loading = ref(false)
// 获取隐藏域的 DOM
const excelUploadInput = ref(null)

// 1： 点击按钮，触发隐藏域的点击事件
const handleUpload = () => {
  excelUploadInput.value.click()
}

// 2：隐藏域点击事件被触发
const handleChange = (e) => {
  const files = e.target.files
  const rawFile = files[0] //  用户只可以选择一个文件
  if (!rawFile) return

  // 当前选中的文件存在，触发隐藏域的上传事件
  upload(rawFile)
}

/**
 *  3：触发隐藏域的上传事件
 */
const upload = (rawFile) => {
  // 清空上一次留下的数据
  excelUploadInput.value.value = null

  // 如果没有指定上传前回调的话
  if (!props.beforeUpload) {
    console.log('!!!!!!!rawFile', rawFile)
    // 读取数据
    readerData(rawFile)
    return
  }
  // 如果指定了上传前回调，那么只有返回 true 才会执行后续操作
  // 得到上传数据的返回值
  const before = props.beforeUpload(rawFile)
  if (before) {
    // 读取数据
    console.log('rawFile', rawFile)

    readerData(rawFile)
  }
}

/**
 * 4：读取数据（异步）
 */
const readerData = (rawFile) => {
  loading.value = true
  return new Promise((resolve, reject) => {
    // https://developer.mozilla.org/zh-CN/docs/Web/API/FileReader
    // 0 : 创建一个文件读取的类
    const reader = new FileReader()
    // 1：该事件在读取操作完成时触发
    // https://developer.mozilla.org/zh-CN/docs/Web/API/FileReader/onload
    reader.onload = (e) => {
      // 1. 获取解析到的数据
      const data = e.target.result
      // 2. 利用 XLSX 对数据进行解析
      const workbook = XLSX.read(data, { type: 'array' })
      // 3. 获取第一张表格(工作簿)名称
      const firstSheetName = workbook.SheetNames[0]
      // 4. 只读取 Sheet1（第一张表格）的数据
      const worksheet = workbook.Sheets[firstSheetName]
      // 5. 解析数据表头 ( 这个解析头是固定的，直接复制就好 )
      const header = getHeaderRow(worksheet)
      // 6. 解析数据体
      const results = XLSX.utils.sheet_to_json(worksheet)
      // 7. 传入解析之后的数据 触发第五步
      generateData({ header, results })
      // 8. loading 处理
      loading.value = false
      // 9. 异步完成
      resolve()
    }
    // 2：启动读取指定的 Blob 或 File 内容
    reader.readAsArrayBuffer(rawFile)
  })
}

/**
 *  5：根据导入内容，生成数据
 */
const generateData = (excelData) => {
  // 将 onSuccess 将数据返回
  props.onSuccess && props.onSuccess(excelData)
}

/** 托拽触发 */

/**
 * 拖拽文本释放时触发
 */
const handleDrop = (e) => {
  // 上传中跳过
  if (loading.value) return
  const files = e.dataTransfer.files
  if (files.length !== 1) {
    ElMessage.error('必须要有一个文件')
    return
  }
  const rawFile = files[0]
  if (!isExcel(rawFile)) {
    ElMessage.error('文件必须是 .xlsx, .xls, .csv 格式')
    return false
  }
  // 触发上传事件
  upload(rawFile)
}

/**
 * 拖拽悬停时触发
 */
const handleDragover = (e) => {
  // https://developer.mozilla.org/zh-CN/docs/Web/API/DataTransfer/dropEffect
  // 在新位置生成源项的副本
  e.dataTransfer.dropEffect = 'copy'
}
</script>

<style lang="scss" scoped>
.upload-excel {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  .excel-upload-input {
    display: none;
    z-index: -9999;
  }
  .btn-upload,
  .drop {
    border: 1px dashed #bbb;
    width: 350px;
    height: 160px;
    text-align: center;
    line-height: 160px;
  }
  .drop {
    line-height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #bbb;
    i {
      font-size: 60px;
      display: block;
    }
  }
}
</style>
